/* eslint-disable react/prop-types */
import React from 'react'
import { PieChart, Pie, Cell, Tooltip } from 'recharts'

const tooltipStyles = {
  backgroundColor: '#5842ffaa',
  border: '1px solid #5842ff',
  borderRadius: '5px',
  color: '#5842ff',
  padding: '0px 3px',
  margin: '0px'
}

const COLORS = ['#0088FE', '#00C49F', '#f71e1e']

const RADIAN = Math.PI / 180

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

const PieChartComp = ({ data }) => {
  const formattedData = Object.values(data).filter(item => item.title !== 'Tout')

  return (
    <PieChart width={160} height={160}>
        <Pie
            data={formattedData}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8e8bdb"
            dataKey="total"
            nameKey="title"
        >
            {formattedData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
        </Pie>
        <Tooltip contentStyle={tooltipStyles} />
    </PieChart>
  )
}

export default PieChartComp
