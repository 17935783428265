/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import {
  Button,
  // Form, Input,
  Modal, Timeline, Typography, message
} from 'antd'
import { useAppContext } from '../contexts/AppContext'
import AttributItem from './AttributItem'
import dayjs from 'dayjs'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { axiosInstance } from '../shared/axiosInstance'
import styled from 'styled-components'

const Title = styled(Typography.Title)`
  border-left: 3px solid #119b7f;
  padding-left: 8px;
`

const SubTitle = styled(Typography.Text)`
  border-left: 3px solid #119b7f;
  padding-left: 8px;
  color: grey;
`

const Details = styled.div`
  margin-top: 12px;
`

const EnrollmentDetails = ({ open, onClose }) => {
  const [mutating, setMutating] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const { sites, trainings, selectedEnrollment } = useAppContext()

  const [messageApi, contextHolder] = message.useMessage()

  const showSuccess = () => {
    messageApi.open({ type: 'success', content: 'Demande sauvegardée!' })
  }

  const getTraining = (idTraining) => {
    const tr = trainings.find(training => training.value === idTraining)
    if (!tr) return idTraining
    return tr.label
  }

  const getSite = (idSite) => {
    const st = sites.find(site => site.value === idSite)
    if (!st) return idSite
    return st.label
  }

  const getNationality = (id) => {
    switch (id) {
      case 0: return 'FRANCAISE'
      case 1: return 'UNION EUROPEENNE'
      case 2: return 'HORS UNION EUROPEENNE'
      default: return 'HORS UNION EUROPEENNE'
    }
  }

  const queryClient = useQueryClient()

  const validateMutation = useMutation(
    {
      mutationFn: (id) => axiosInstance.post('/enrollments/save/' + id),
      onSuccess: () => {
        onClose()
        showSuccess()
        queryClient.invalidateQueries({ queryKey: ['enrollments'] })
      }
    }
  )

  const remindMutation = useMutation(
    {
      mutationFn: (payload) => axiosInstance.post('/enrollments/reminder', payload),
      onMutate: () => {
        setMutating(true)
      },
      onSuccess: () => {
        onClose()
        showSuccess()
        setMutating(false)
        queryClient.invalidateQueries({ queryKey: ['enrollments'] })
      },
      onError: () => {
        onClose()
      }
    }
  )

  const rejectMutation = useMutation(
    {
      mutationFn: (id) => axiosInstance.post('/enrollments/reject/' + id),
      onSuccess: () => {
        onClose()
        showSuccess()
        queryClient.invalidateQueries({ queryKey: ['enrollments'] })
      }
    }
  )

  const validateHandler = (id) => {
    validateMutation.mutate(id)
  }

  const remindHandler = (uuid) => {
    remindMutation.mutate({ uuid })
  }

  const rejectHandler = (id) => {
    rejectMutation.mutate(id)
  }

  if (!selectedEnrollment) return null

  const {
    id, uuid, firstName, surname, dateOfBirth, nationality, address,
    email, telephone, idSite, idTraining, accepted, rejected,
    cv, motivationLetter, bulletin, otherDoc, reminderSequence, actions
  } = selectedEnrollment

  const basePath = `${process.env.REACT_APP_SERVER_BASE_URL}/attachments/${uuid}`

  return (
      <Modal
        title="Candidat"
        open={open}
        okText='Valider la demande'
        onCancel={onClose}
        footer={(accepted || rejected)
          ? null
          : <>
          <Button danger onClick={() => rejectHandler(id)}>Refuser</Button>
          {reminderSequence < 3 && <Button loading={mutating} type='dashed' onClick={() => remindHandler(uuid)}>Relancer</Button>}
          <Button type='primary' onClick={() => validateHandler(id)}>Accepter</Button>
        </>}
        width={800}
      >
        {contextHolder}
        <Title level={5}>{`${getTraining(idTraining)}`}</Title>
        <SubTitle>{`${getSite(idSite)}`}</SubTitle>
        <Details>
          <AttributItem title='Nom et prénom' value={`${firstName} ${surname}`} />
          <AttributItem title='Date de naissance' value={`${dayjs(dateOfBirth).format('DD/MM/YYYY')}`} />
          <AttributItem title='Nationalité' value={getNationality(nationality)} />
          <AttributItem title='Adresse' value={`${address}`} />
          <AttributItem title='E-mail' value={`${email}`} />
          <AttributItem title='Téléphone' value={`${telephone}`} />
          <AttributItem title='CV' value={cv} link={basePath} />
          <AttributItem title='LM' value={motivationLetter} link={basePath} />
          <AttributItem title='Bulletin' value={bulletin} link={basePath} />
          <AttributItem title='Autre' value={otherDoc} link={basePath}/>
        </Details>
        <Typography.Title level={5}>Historique</Typography.Title>
        {actions.length !== 0 &&
        <Timeline
          items={actions.map(({ action, created_at }) =>
            ({
              children: (<>
              <span>{`${action} émise le `}</span>
              <span><b>{`${dayjs(created_at).format('DD/MM/YYYY')} `}</b></span>
              <span>à</span>
              <span><b>{` ${dayjs(created_at).format('HH:mm')}`}</b></span>
              </>)
            })
          )}
        />}
        {actions.length === 0 && <b>Aucune action</b>}
      </Modal>
  )
}

export default EnrollmentDetails
