/* eslint-disable react/prop-types */
import { Select, Typography } from 'antd'
import React from 'react'

function DropDown ({ name, data, register, onChange }) {
  return (
        <div className="panel">
            <Typography variant="p" className="header">
                {name}
            </Typography>
            <Select
                style={{ width: 350 }}
                {...register}
                defaultValue={data[0]?.name}
                onChange={onChange}
                options={data.map(i => ({ value: i.value, label: i.name }))}
            />
        </div>
  )
}

export default DropDown
