/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import Button from './Button'
import React, { useContext } from 'react'
import { GlobalContext } from '../contexts/GlobalContext'
import { sign_in } from '../api/calendar'
import { Checkbox } from 'antd'

function Table ({ registrations, onIsAbsent, onSendEmail }) {
  return (
    <div className="table shadowed_panel_2">
      <table>
        <thead>
          <td>nom</td>
          <td>prenom</td>
          <td>telephone</td>
          <td>email</td>
          <td>convocation</td>
          <td>absent</td>
        </thead>
        <tbody>
          {registrations.map((registration, registrationIndex) => (
            <TableRow
              key={registrationIndex}
              registration={registration}
              onIsAbsent={onIsAbsent}
              onSendEmail={onSendEmail}
              registrationIndex={registrationIndex}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

const TableRow = ({
  registration,
  onIsAbsent,
  onSendEmail,
  registrationIndex
}) => {
  const { isSignedIn } = useContext(GlobalContext)

  return (
    <tr>
      <div className="row">
        <div className="inscription">
          <div className="name">
            <td>{registration.first_name}</td>
            <td>{registration.last_name}</td>
          </div>
          <div className="inscription_details">
            <td>{registration.phone}</td>
            <td>{registration.email}</td>
          </div>
        </div>
        <div
          className="inscription_actions"
          data-registration={registrationIndex}
        >
          <td>
            {!registration.email_sent_time
              ? (
                  isSignedIn
                    ? (
                <Button label="envoyer" onClick={onSendEmail} />
                      )
                    : (
                <Button label="se Connecter" onClick={sign_in} />
                      )
                )
              : (
                  'Envoyé'
                )}
          </td>
          <td>
            <Checkbox
              defaultChecked={registration.is_absent}
              onChange={onIsAbsent}
            />
          </td>
        </div>
      </div>
    </tr>
  )
}

export default Table
