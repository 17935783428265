import { Button, Card, Form, Input, Typography, message } from 'antd'
import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { axiosInstance } from '../shared/axiosInstance'
import { useAppContext } from '../contexts/AppContext'
import addIcon from '../assets/add.svg'
const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  padding-top: 150px;
  height: 300px;
`

const StyledCard = styled(Card)`
.ant-card-body {
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
`

export default function EnrollmentHome () {
  const formRef = useRef()
  const navigate = useNavigate()
  const { setResuming } = useAppContext()

  const [messageApi, contextHolder] = message.useMessage()

  const onFinish = async (values) => {
    const queryParams = new URLSearchParams(values).toString()
    const { data: payload } = await axiosInstance.get(`/enrollments/resume?${queryParams}`)
    console.log(payload.data)
    if (payload.data) {
      setResuming(payload.data)
      navigate('/inscription')
    } else {
      formRef.current.resetFields()
      showError()
    }
  }

  const showError = () => {
    messageApi.open({
      type: 'error',
      content: 'Aucune demande ne correspond à vos coordonnées'
    })
  }

  return (
    <Wrapper>
       {contextHolder}
        <StyledCard hoverable onClick={() => navigate('/inscription')}>
          <Typography.Title level={4}>Nouvelle demande</Typography.Title>
          <img height={80} width={80} src={addIcon} alt="icon add" />
        </StyledCard>
        <StyledCard hoverable>
          <Typography.Title level={4}>Reprendre ma demande</Typography.Title>
        <Form
          ref={formRef}
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
          style={{ width: '100%', textAlign: 'center' }}
        >
            <Form.Item name="email" rules={[{ required: true, type: 'email' }]} >
              <Input placeholder='Email' />
            </Form.Item>

            <Form.Item name="surname" rules={[{ required: true }]} >
              <Input placeholder="Nom" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Reprendre
              </Button>
            </Form.Item>
          </Form>
        </StyledCard>
    </Wrapper>
  )
}
