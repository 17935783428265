/* eslint-disable camelcase */
let timeout

export const debounce = function (func, delay) {
  clearTimeout(timeout)

  timeout = setTimeout(func, delay)
}

export const getFormattedAddress = (details) => {
  const { adresse_ligne_1, adresse_ligne_2 = '', code_postal, ville } = details
  return `${adresse_ligne_1} ${adresse_ligne_2 || ''}, ${code_postal} ${ville}`
}

export const getTotalEmployees = (total) => {
  const parsedEffectif = parseInt(total)
  const totalEmployees = isNaN(parsedEffectif) ? null : parsedEffectif

  return totalEmployees
}

export const getFormattedCompanyName = (companyName = '') => {
  return companyName.slice(0, 37)
}

export const getFormattedNAF = (codeNAF = '') => {
  return codeNAF.length > 5 ? codeNAF.replace('.', '') : codeNAF
}

export const generateUniqueId = () => Math.random().toString(16).slice(2)

export const calculateAge = (birthday) => {
  const ageDifMs = Date.now() - new Date(birthday)
  const ageDate = new Date(ageDifMs) // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

export const getGenderLabel = (id) => {
  switch (id) {
    case 1: return 'M.'
    case 2: return 'Mme'
    default: return ''
  }
}

export function createSlug (sentence = '') {
  return sentence.toLowerCase().replace(/[^\w\s]/g, '').replace(/ /g, '-')
}
