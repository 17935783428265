import axios from 'axios' // node

const config = {
  baseURL: process.env.REACT_APP_API,
  // timeout: 3000,
  withCredentials: true,
  headers: {
    Authorization: `${window.localStorage.getItem('access_token') || ''}`,
    'X-Graph-Token': `${window.localStorage.getItem('graph_token') || ''}`
  }
}

const api = {
  config,
  client: axios.create(config),
  refreshAcessToken: async () => {
    config.headers.Authorization = `Bearer ${window.btoa(unescape(encodeURIComponent(`{"KK_PK":"${process.env.REACT_APP_REFRESH_KEY}"}`)))}`
    const res = await api.client.post(process.env.REACT_APP_API + '/auth/token', null, config)
    console.log(res)
    if (res.data.success === true) { window.localStorage.setItem('access_token', res.data.access_token) }

    return window.localStorage.getItem('access_token')
  }
}

api.client.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response
}, async function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response.status === 401) { await api.refreshAcessToken() }

  return Promise.reject(error)
})

export default api
