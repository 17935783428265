import React, { createContext, useContext, useState } from 'react'
import { axiosInstance } from '../shared/axiosInstance'
import { useQuery } from '@tanstack/react-query'

export const AppContext = createContext()

// eslint-disable-next-line react/prop-types
export const AppContextProvider = ({ children }) => {
  const [selectedEnrollment, selectEnrollment] = useState(null)
  const [resuming, setResuming] = useState(null)

  const fetchSites = async () => {
    const res = await axiosInstance.get('/trainings/sites')
    return Object.values(res.data)
      .filter(site => site.plusUtilise === 0)
      .map(site => ({
        value: site.codeSite,
        label: site.nomSite
      }))
  }

  const fetchTrainings = async () => {
    const res = await axiosInstance.get('/trainings')
    return Object.values(res.data)
      .filter(training => training.plusUtilise === 0 && training.estFormationLongue === 1)
      .map(site => ({
        value: site.codeFormation,
        label: site.etenduFormation
      }))
  }

  const { data: sites = [] } = useQuery({
    queryKey: ['sites'],
    queryFn: fetchSites
  })

  const { data: trainings = [] } = useQuery({
    queryKey: ['trainings'],
    queryFn: fetchTrainings
  })

  return (
    <AppContext.Provider value={{
      sites,
      trainings,
      resuming,
      setResuming,
      selectedEnrollment,
      selectEnrollment
    }}>
        {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => useContext(AppContext)
