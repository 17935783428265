/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react'
import { Button, Card, Checkbox, DatePicker, Form, Input, Modal, Select, Table, Typography, Upload, message } from 'antd'
import { axiosInstance } from '../shared/axiosInstance'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createSlug, generateUniqueId } from '../shared/helpers'
import { useAppContext } from '../contexts/AppContext'
import { CloudUploadOutlined, CheckCircleTwoTone } from '@ant-design/icons'
import Success from '../components/Success'
import locale from 'antd/es/date-picker/locale/fr_FR'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useMediaQuery } from 'react-responsive'

const { Option } = Select
const { Text } = Typography

const UploadInput = ({ file, fileType, userInfo, uniqueId, onChange }) => {
  if (file.info) {
    return <h6 style={{ margin: 0 }}>
            <CheckCircleTwoTone size={30} twoToneColor="#52c41a" style={{ marginRight: 5 }}/>{file.info.name}
           </h6>
  }

  const slug = createSlug(`${fileType} ${userInfo.firstName} ${userInfo.surname}`)
  const queryParams = `key=${uniqueId}&slug=${slug}`
  const actionURL = `${process.env.REACT_APP_API_BASE_URL}/uploads?${queryParams}`

  return <Form.Item name={file.key} style={{ margin: 0 }}>
        <Upload
            maxCount={1}
            name='attachment'
            action={actionURL}
            onChange={(state) => onChange(state, file.key)}
            >
            <Button icon={<CloudUploadOutlined />} size='small' type='link'>Ajouter</Button>
        </Upload>
    </Form.Item>
}

const onChange = (value) => {
  console.log(`selected ${value}`)
}
const onSearch = (value) => {
  console.log('search:', value)
}

// Filter `option.label` match the user type `input`
const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

const data = {
  cv: {
    key: 'cv',
    attachmentType: 'CV'
  },
  motivationLetter: {
    key: 'motivationLetter',
    attachmentType: 'Lettre de motivation'
  },
  bulletin: {
    key: 'bulletin',
    attachmentType: 'Bulletin'
  },
  otherDoc: {
    key: 'otherDoc',
    attachmentType: 'Autre document'
  }
}

const Enrollment = () => {
  const [uniqueId, setUniqueId] = useState(null)
  const [uploaded, setUploaded] = useState(data)
  const [submitted, setSubmitted] = useState(false)
  const [citySuggestions, setCitySuggestions] = useState([])

  const navigate = useNavigate()
  const [form] = Form.useForm()
  const postalCode = Form.useWatch('postalCode', form)
  const firstName = Form.useWatch('firstName', form)
  const surname = Form.useWatch('surname', form)

  const formRef = useRef()

  const isMobile = useMediaQuery({ maxWidth: 767 })

  const [messageApi, contextHolder] = message.useMessage()

  const { sites, trainings, resuming = {}, setResuming } = useAppContext()
  const initialValues = resuming && { ...resuming, dateOfBirth: dayjs(resuming.dateOfBirth) }

  const showError = (message) => {
    messageApi.open({ type: 'error', content: message })
  }

  const fileUploadStateChangeHandler = ({ file }, fileKey) => {
    if (file.status === 'error') {
      showError(file.response.message)
    }
    if (file.status === 'done') {
      const uploadedCopy = { ...uploaded }
      uploadedCopy[fileKey] = { ...uploadedCopy[file], uploaded: true, info: file }

      setUploaded(uploadedCopy)
    }
  }

  const columns = [
    {
      title: '',
      dataIndex: 'attachmentType'
    },
    {
      title: 'Pièces jointes',
      dataIndex: 'attachment',
      render: (_, { key }) => <UploadInput
        onChange={fileUploadStateChangeHandler}
        uniqueId={uniqueId}
        file={uploaded[key]}
        fileType={key}
        userInfo={{ firstName, surname }}
      />
    }
  ]

  const queryClient = useQueryClient()

  const createMutationFn = (payload) => axiosInstance.post('/enrollments', payload)
  const updateMutationFn = (payload) => axiosInstance.put(`/enrollments/${resuming.uuid}`, payload)

  const mutation = useMutation(
    {
      mutationFn: resuming ? updateMutationFn : createMutationFn,
      onSuccess: () => {
        setSubmitted(true)
        setUploaded(data)
        setResuming(null)
        queryClient.invalidateQueries({ queryKey: ['enrollments'] })
      }
    }
  )

  const onFinish = (formValues) => {
    const payload = {
      ...formValues,
      uuid: (resuming || {}).uuid || uniqueId,
      cv: uploaded.cv.info?.response.file.filename,
      motivationLetter: uploaded.motivationLetter.info?.response.file.filename,
      bulletin: uploaded.bulletin.info?.response.file.filename,
      otherDoc: uploaded.otherDoc.info?.response.file.filename
    }

    mutation.mutate(payload)
  }

  const closeModalHandler = () => {
    setSubmitted(false)
    formRef.current.resetFields()
    window.scrollTo({ top: 0, behavior: 'smooth' })
    navigate('/inscription-accueil')
  }

  useEffect(() => {
    if (resuming && resuming.uuid) setUniqueId(resuming.uuid)
    else {
      setUniqueId(generateUniqueId())
    }
  }, [resuming])

  useEffect(() => {
    const getCitySuggestions = async () => {
      if (!postalCode || postalCode.length < 5) return

      const resp = await axios.get(`${process.env.REACT_APP_GEO_API_BASE_URL}?codePostal=${postalCode}`)

      setCitySuggestions(resp.data)
    }
    getCitySuggestions()
  }, [postalCode])

  const itemWidth = isMobile ? '100%' : 'calc(50% - 8px)'

  return (
        <Card style={{ marginBottom: '20px' }}>
            {contextHolder}
            <Modal open={submitted} onCancel={closeModalHandler} footer={null}>
              <Success onClose={closeModalHandler} />
            </Modal>
            <h2>{'Formulaire d\'inscription'}</h2>
            <Form
              ref={formRef}
              form={form}
              initialValues={initialValues}
              onFinish={onFinish}
              layout="vertical"
              variant="filled"
              style={{ width: isMobile ? '100%' : 600 }}
            >
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        name="gender" label="Civilité" rules={[{ required: true }]}
                        style={{ display: 'inline-block', width: isMobile ? '100%' : 'calc(20% - 8px)', marginRight: '8px' }}
                    >
                      <Select placeholder="Civilité" size='large'>
                          <Option value={1}>Monsieur</Option>
                          <Option value={2}>Madame</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                        label="Prénom"
                        name="firstName"
                        rules={[{ required: true }]}
                        style={{ display: 'inline-block', width: isMobile ? '100%' : 'calc(40% - 8px)' }}
                    >
                        <Input placeholder="Votre prénom" size='large' />
                    </Form.Item>
                    <Form.Item
                        label="Nom"
                        name="surname"
                        rules={[{ required: true }]}
                        style={{ display: 'inline-block', width: isMobile ? '100%' : 'calc(40% - 8px)', margin: '0 8px' }}
                    >
                        <Input placeholder="Votre nom" size='large' />
                    </Form.Item>
                </Form.Item>

                <Form.Item style={{ margin: '15px 0 0 0' }}>
                    <Form.Item label="Date de naissance" name="dateOfBirth" rules={[{ required: true }]}
                      style={{ display: 'inline-block', width: itemWidth, marginRight: '8px' }}>
                        <DatePicker locale={locale} size='large' format='DD/MM/YYYY' style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="nationality" label="Nationalié" rules={[{ required: true }]}
                        style={{ display: 'inline-block', width: itemWidth, marginRight: '8px' }}
                    >
                      <Select placeholder="Votre nationalité" size='large'>
                          <Option value={0}>FRANCAISE</Option>
                          <Option value={1}>UNION EUROPEENNE</Option>
                          <Option value={2}>HORS UNION EUROPEENNE</Option>
                      </Select>
                    </Form.Item>
                </Form.Item>

                <Form.Item label="Adresse" name="address" rules={[{ required: true }]}>
                        <Input size='large' placeholder='Votre adresse postale' />
                </Form.Item>

                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item label="Code postal" name="postalCode" rules={[{ required: true }]}
                               style={{ display: 'inline-block', width: itemWidth, marginRight: '8px' }}>
                        <Input size='large' placeholder='Code postal' />
                    </Form.Item>
                    <Form.Item label="Ville" name="city" rules={[{ required: true }]}
                               style={{ display: 'inline-block', width: itemWidth }}>
                      <Select placeholder="Ville" size='large'>
                        {citySuggestions.map(({ nom, code }) => (<Option value={nom} key={code}>{nom}</Option>))}
                      </Select>
                    </Form.Item>
                </Form.Item>

                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item label="Téléphone" name="telephone" rules={[{ required: true }]}
                               style={{ display: 'inline-block', width: itemWidth, marginRight: '8px' }}>
                        <Input size='large' placeholder='Votre numéro de téléphone' />
                    </Form.Item>
                    <Form.Item label="E-mail" name="email" rules={[{ required: true, type: 'email' }]}
                               style={{ display: 'inline-block', width: itemWidth }}>
                        <Input size='large' placeholder='Votre adresse e-mail' />
                    </Form.Item>
                </Form.Item>

                <Form.Item label="Site de formation" name="idSite" rules={[{ required: true }]} >
                      <Select
                        showSearch
                        placeholder="Sélectionner un site de formation"
                        size='large'
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={filterOption}
                        options={sites}
                    />
                </Form.Item>

                <Form.Item label="Formation souhaitée" name="idTraining" rules={[{ required: true }]} >
                      <Select
                        showSearch
                        placeholder="Sélectionner la formation souhaitée"
                        size='large'
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={filterOption}
                        options={trainings}
                    />
                </Form.Item>

                <Table
                    columns={columns}
                    dataSource={Object.values(data)}
                    pagination={false}
                    style={{ marginBottom: 30 }}
                    size="small"
                />

                <Form.Item label="Commentaire" name="comment">
                    <Input.TextArea placeholder='Commentaire...' rows={5} />
                </Form.Item>

                <Form.Item label="Consentement RGPD" name="rgpd" valuePropName="checked" layout="horizontal" rules={[{ required: true }]}>
                    <Checkbox>{'J\'accepte'}</Checkbox>
                </Form.Item>
                <Text type="secondary">
                    {'Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par Talez Consulting pour permettre de vous adresser des contenus adaptés à votre demande. Elles sont conservée pendant 3 ans et sont destinées au service marketing. Conformément à la loi "informatique et libertés" et au RGPD du 25 mai 2018, vous pouvez exercer votre droit d\'accès aux données vous concernant et les faire rectifier. Pour cela, consultez notre Politique de Confidentialité.'}
                </Text>

                <Form.Item style={{ marginTop: 30, textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit">
                        {resuming ? 'Mettre à jour ma demande' : 'Envoyer ma demande'}
                    </Button>
                </Form.Item>
            </Form>
        </Card>
  )
}

export default Enrollment
