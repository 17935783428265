/* eslint-disable no-unused-vars */
import { Spin } from 'antd'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function AuthGraph () {
  const navigate = useNavigate()

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const graphToken = urlParams.get('token')

    if (graphToken) localStorage.setItem('graph_token', graphToken)
    navigate('/ajouter-rdv', { replace: true })
  }, [])

  return (
    <div>
        <Spin tip="Connexion en cours" size="large" spinning />
    </div>
  )
}
