/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { useForm } from 'react-hook-form'
import { createAppointment } from '../api/appointment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { GlobalContext } from '../contexts/GlobalContext'
import { sign_in } from '../api/calendar'
import { Button, DatePicker, Form, Input, message } from 'antd'

import locale from 'antd/es/date-picker/locale/fr_FR'

function Appointment () {
  const { isSignedIn } = useContext(GlobalContext)
  const [notificationData, setNotificationData] = useState({})

  const formRef = useRef()

  const [messageApi, contextHolder] = message.useMessage()

  // const onSign_in = () => {
  //   sign_in()
  // }

  const formSubmitHandler = (appointment) => {
    const newAppointment = {
      speaker: appointment.speaker,
      registration_maximum: appointment.maxParticipants,
      start_time: appointment.startTime.toISOString(),
      end_time: appointment.endTime.toISOString()
    }

    createAppointment(newAppointment).then(res => {
      if (res?.status === 200) {
        formRef.current.resetFields()
        setNotificationData({ success: res.data.success, message: res.data.message })
        showSuccessMessage(res.data.message)
      } else {
        showErrorMessage(res.response.data.error)
        setNotificationData({ success: res.response.data.success, message: res.response.data.error })
      }
    }).catch(() => {
      showErrorMessage()
      setNotificationData({ success: false, message: process.env.REACT_APP_UNKNOWN_ERROR })
    })
  }

  const showSuccessMessage = (successMessage) => {
    messageApi.open({
      type: 'success',
      content: notificationData.message || successMessage
    })
  }

  const showErrorMessage = () => {
    messageApi.open({
      type: 'error',
      content: notificationData.message
    })
  }

  useEffect(() => {
    const graphToken = localStorage.getItem('graph_token')
    if (!graphToken) {
      sign_in()
    }
  }, [])

  return (
        <div>
            <Form
            ref={formRef}
              onFinish={formSubmitHandler}
              layout="vertical"
              variant="filled"
              style={{ width: 600 }}
            >
              <Form.Item label="Heure de début" name="startTime" rules={[{ required: true }]}
                style={{ display: 'inline-block', marginRight: '8px', width: 'calc(50% - 8px)' }}>
                  <DatePicker
                  showTime
                  locale={locale}
                  needConfirm={false}
                  size='large'
                  format='DD/MM/YYYY HH:mm'
                  style={{ width: '100%' }}
                  />
              </Form.Item>
              <Form.Item label="Heure de fin" name="endTime" rules={[{ required: true }]}
                style={{ display: 'inline-block', width: '50%' }}>
                  <DatePicker
                  showTime
                  locale={locale}
                  needConfirm={false}
                  size='large'
                  format='DD/MM/YYYY HH:mm'
                  style={{ width: '100%' }}
                  />
              </Form.Item>
                <Form.Item label="Intervenant" name="speaker" rules={[{ required: true }]}
                            style={{ width: '100%' }}>
                    <Input size='large' placeholder='Nom intervenant' />
                </Form.Item>

                <Form.Item
                  label="Nombre maximum de participants"
                  name="maxParticipants"
                  rules={[{ required: true, message: "Nombre de participants n'est pas valide", pattern: /^[0-9]+$/ }]}
                  style={{ width: '100%' }}
                >
                    <Input size='large' placeholder='Nombre de participants' type='number'/>
                </Form.Item>

                <Form.Item style={{ marginTop: 30, textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit" disabled={!isSignedIn}>Créer</Button>
                </Form.Item>
            </Form>
            {contextHolder}
        </div>
  )
}

export default Appointment
