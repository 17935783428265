import React from 'react'
import Navbar from './Navbar'
import SideMenu from './SideMenu'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'

const MainWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
`

const MenuWrapper = styled.div`
  min-height: calc(100vh - 80px);
`

const MainSection = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`

export default function Layout ({ children }) {
  const isMobile = useMediaQuery({ maxWidth: 767 })

  return (
    <div className="App">
      <div style={{ width: '100%' }}><Navbar /></div>
      <MainWrapper>
        {!isMobile && <MenuWrapper><SideMenu/></MenuWrapper>}
        <MainSection>{children}</MainSection>
      </MainWrapper>
    </div>
  )
}

Layout.propTypes = {
  children: React.ReactNode
}
