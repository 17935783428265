/* eslint-disable react/prop-types */
import { Button } from 'antd'
import React from 'react'

function CustomButton ({ label, onSubmit, onClick }) {
  return (
        <Button onSubmit={onSubmit} onClick={onClick && onClick} type="primary">
                {label}
        </Button>
  )
}

export default CustomButton
