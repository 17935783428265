/* eslint-disable camelcase */
import api from './api'

const endpoint = '/calendar'

const sign_in = async () => {
  try {
    const clientId = process.env.REACT_APP_OUTLOOK_CLIENT_ID
    const tenantId = process.env.REACT_APP_OUTLOOK_TENANT_ID
    const redirectUri = process.env.REACT_APP_OUTLOOK_REDIRECT_URI

    // return await api.client.get(`${endpoint}/sign_in`)

    window.open(`https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&response_mode=query&scope=openid profile offline_access User.Read Calendars.ReadWrite`)
    window.open('about:blank', '_self')
    window.close()
  } catch (error) {
    return error
  }
}

const is_signed_in = async () => {
  try {
    return await api.client.get(`${endpoint}/is_signed_in`)
  } catch (error) {
    return error
  }
}

const sign_out = async () => {
  try {
    return await api.client.get(`${endpoint}/sign_out`)
  } catch (error) {
    return error
  }
}

export { sign_in, is_signed_in, sign_out }
