import DropDown from '../components/DropDown'
import Table from '../components/Table'
import React, { useEffect, useReducer, useState } from 'react'
import { getAllAppointments } from '../api/appointment'
import { getRegistrationByAppointment, sendEmail, updateRegistration } from '../api/registration'
import registrationReducer, { initialRegistrations } from '../reducers/globalReducer'
import { Typography, message } from 'antd'

function Invocation () {
  const [appointments, setAppointments] = useState([])
  const [notificationData, setNotificationData] = useState({})

  const [registrations, dispatchRegistrations] = useReducer(registrationReducer, initialRegistrations)

  const [messageApi, contextHolder] = message.useMessage()

  const onAppointmentChange = (eventId) => {
    getRegistrationByAppointment(eventId).then(res => {
      dispatchRegistrations({ type: 'ADD_ALL', data: res.data.data })
    }).catch((err) => {
      console.log(err)

      showErrorMessage(err.toString())
      setNotificationData({ success: false, message: process.env.REACT_APP_UNKNOWN_ERROR })
    })
  }

  const onIsAbsent = async (event) => {
    await updateRegistration({ is_absent: event.target.checked, id: registrations[event.target.parentNode.parentNode.parentNode.getAttribute('data-registration')].id })
      .then(res => {
        showSuccessMessage()
        setNotificationData({ success: res.data.success, message: res.data.success ? res.data.message : res.data.error })
      }).catch((err) => {
        console.log(err)
        showErrorMessage(err.toString())
        setNotificationData({ success: false, message: process.env.REACT_APP_UNKNOWN_ERROR })
      })
  }

  const onSendEmail = async (event) => {
    sendEmail({ registration: { id: registrations[event.target.parentNode.parentNode.getAttribute('data-registration')].id } })
      .then(res => {
        showSuccessMessage()

        if (res?.status === 200) {
          dispatchRegistrations({ type: 'UPDATE', index: event.target.parentNode.parentNode.getAttribute('data-registration'), value: new Date() })
          setNotificationData({ success: res.data.success, message: res.data.message })
        } else { setNotificationData({ success: res.response.data.success, message: res.response.data.error }) }
      }).catch((err) => {
        console.log(err)
        showErrorMessage(err.toString())
        setNotificationData({ success: false, message: process.env.REACT_APP_UNKNOWN_ERROR })
      })
  }

  const showSuccessMessage = () => {
    messageApi.open({
      type: 'success',
      content: notificationData.message
    })
  }

  const showErrorMessage = (errorMessage) => {
    messageApi.open({
      type: 'error',
      content: notificationData.message || errorMessage
    })
  }

  useEffect(() => {
    const shortTime = new Intl.DateTimeFormat(process.env.DATE_LOCALE, {
      timeStyle: 'short'
    })

    const shortDate = new Intl.DateTimeFormat(process.env.DATE_LOCALE, {
      dateStyle: 'short'
    })

    getAllAppointments().then(res => {
      if (res.data) {
        setAppointments(res?.data.data.map(registration => ({ value: registration.id, name: registration.speaker + ' ' + shortDate.format(new Date(registration.start_time)) + ' ' + shortTime.format(new Date(registration.start_time)) + ' ' + shortTime.format(new Date(registration.end_time)) })))
      }
    }).catch((err) => {
      console.log(err)
      showErrorMessage(err.toString())
      setNotificationData({ success: false, message: process.env.REACT_APP_UNKNOWN_ERROR })
    })
  }, [])

  return (
        <div>
            <div>
                <div style={{ display: 'flex', gap: 20 }}>
                    <DropDown name="rendez-vous:" data={appointments} onChange={onAppointmentChange} />
                    <div>
                        <Typography className="registartions_count_name" variant="p">inscripts</Typography>
                        <Typography className="registartions_count_value">{registrations.length}</Typography>
                    </div>

                </div>
                {registrations.length > 0 && <Table registrations={registrations} onSendEmail={onSendEmail} onIsAbsent={onIsAbsent} />}
            </div>

            {contextHolder}
        </div>
  )
}

export default Invocation
