import React, { useState } from 'react'
import { ClockCircleOutlined, SnippetsOutlined, SettingOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

function getItem (label, key, icon, children, type) {
  return { key, icon, children, label, type }
}

const items = [
  getItem('Candidatures', 'sub1', <SnippetsOutlined />, [
    getItem('Liste des candidatures', 'candidatures'),
    getItem('Ajouter', 'inscription-accueil')
  ]),

  getItem('Rendez-vous', 'sub2', <ClockCircleOutlined />, [
    getItem('Demendes de RDV', 'inscriptions-rdv'),
    getItem('Ajouter', 'ajouter-rdv')
    // getItem('Submenu', 'sub3', null, [getItem('Option 7', '7'), getItem('Option 8', '8')])
  ]),

  getItem('Paramètres', 'sub4', <SettingOutlined />, [
    getItem('Mes paramètres', 'parametres')
  ])
]

// eslint-disable-next-line react/prop-types
const SideMenu = ({ closeDrawer = () => null }) => {
  const [current, setCurrent] = useState('1')
  const navigate = useNavigate()

  const isMobile = useMediaQuery({ maxWidth: 767 })

  const onClick = (e) => {
    console.log('click ', e)
    setCurrent(e.key)
    navigate(e.key)
    closeDrawer()
  }

  return (
    <div style={{ height: '100%' }}>
      <Menu
        onClick={onClick}
        style={{ width: isMobile ? '100%' : 250, height: '100%' }}
        defaultOpenKeys={['sub1']}
        selectedKeys={[current]}
        mode="inline"
        items={items}
      />
    </div>
  )
}
export default SideMenu
