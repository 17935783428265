import React, { useState, useEffect } from 'react'

import { debounce, getFormattedAddress, getFormattedCompanyName, getFormattedNAF, getTotalEmployees } from '../shared/helpers'
import { axiosInstance } from '../shared/axiosInstance'
import AttributItem from '../components/AttributItem'

function PappersSearch () {
  const [q, setQ] = useState('')
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [waiting, setWaiting] = useState(false)
  const [results, setResults] = useState([])

  const callSearchAPI = (query) => {
    axiosInstance.get(`/suggestions?name=${query}`)
      .then(({ data }) => setResults(data.resultats_nom_entreprise || []))
      .catch(() => setError({ message: 'PAPPERS API: Une erreur est survenue' }))
  }

  const showError = (errorObj) => {
    setError(errorObj)
    setTimeout(() => setError(null), 3000)
  }

  const showSuccess = (companyId) => {
    setSuccess(companyId)
    setTimeout(() => setSuccess(null), 3000)
  }

  const saveCompanyHandler = (company) => {
    const newCompany = {
      nomEntreprise: getFormattedCompanyName(company.nom_entreprise),
      siret: company.siege.siret,
      adr1: company.siege.adresse_ligne_1,
      adr2: company.siege.adresse_ligne_2,
      cp: company.siege.code_postal,
      ville: company.siege.ville,
      nomenclNaf: getFormattedNAF(company.code_naf),
      effectif: getTotalEmployees(company.tranche_effectif)
    }

    setWaiting(true)
    setSuccess(null)
    setError(null)

    axiosInstance.post('/companies', newCompany)
      .then(({ data }) => {
        setWaiting(false)

        if (data.error) {
          showError(data.error)
        } else {
          showSuccess(data)
        }
      })
      .catch((err) => {
        console.log(err)
        showError({ message: 'Une erreur est survenue' })
        setWaiting(false)
      })
  }

  useEffect(() => {
    if (q) {
      debounce(() => callSearchAPI(q), 200)
    } else {
      debounce(() => setResults([]), 200)
    }
  }, [q])

  return (
      <main className='main'>
        <div className='autocomplete-wrapper'>
          <input
            value={q}
            onChange={(e) => setQ(e.target.value)}
            className='autocomplete'
            placeholder='Rechercher une entreprise'
          />
          {error && <div className='message error'>{error.message}</div>}
          {success && <div className='message success'>{`L'entreprise a bien été sauvegardée avec l'identifiant ${success}`}</div>}
          <hr className="solid" />
        </div>
        {results.length === 0 && <h6>Pas de résultat</h6>}
        <ul className='results'>
          {results.map(result => <li key={result.siren}>
            <div>
              <div className='card-header'>
                <AttributItem title="" value={result.nom_entreprise || result.denomination} />
                <button onClick={() => saveCompanyHandler(result)} disabled={waiting}>AJOUTER</button>
              </div>
              <AttributItem title="Adresse" value={getFormattedAddress(result.siege)} />
              <AttributItem title="N° SIRET" value={result.siege.siret} />
              <AttributItem title="Code NAF" value={result.code_naf} />
              <AttributItem title="Effectif" value={getTotalEmployees(result.tranche_effectif) || 'N/A'} />
            </div>
          </li>)}
        </ul>
      </main>
  )
}

export default PappersSearch
