const initialRegistrations = []

const registrationReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE':
      return state.map((registration, index) => {
        if (index === action.index) {
          return { ...registration, email_sent_time: action.value }
        } else {
          return registration
        }
      })
    case 'ADD_ALL':
      return [...action.data]
    default:
      return state
  }
}

export { registrationReducer as default, initialRegistrations }
