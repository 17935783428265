import React, { useState } from 'react'
import { Drawer, Menu } from 'antd'
import logo from '../assets/logo.png'
import styled from 'styled-components'
import { MenuOutlined } from '@ant-design/icons'
import { useMediaQuery } from 'react-responsive'
import SideMenu from './SideMenu'

const NavbarMenu = styled(Menu)`
  background-color: #16a085;
  .ant-menu-item {
    top: 5px!important;
  }
`

const LogoImg = styled.img`
    height: 40px;
    max-width: 150px;
    padding-top: 5px;
`

const DrawerMenu = styled(Drawer)`
  .ant-drawer-header {
  background-color: #16a085;
  }
`

const desktopItems = [
  {
    label: '',
    key: 'home',
    icon: <LogoImg src={logo} alt="Talez Consulting - Expert Yparéo" loading="lazy" />
  }
]

const mobileItems = [
  {
    label: '',
    key: 'drawer',
    disabled: false,
    icon: <MenuOutlined style={{ color: 'white' }} />
  },
  {
    label: '',
    key: 'home',
    icon: <LogoImg src={logo} alt="Talez Consulting - Expert Yparéo" loading="lazy" />
  }
]
const Navbar = () => {
  // const [current, setCurrent] = useState('mail')
  const [isVisible, setIsVisible] = useState(false)
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const onClick = (e) => {
    console.log('click ', e)
    // setCurrent(e.key)
    showDrawer()
  }

  // trigger this function to open the drawer
  const showDrawer = () => {
    setIsVisible(true)
  }

  // close the drawer
  const closeDrawer = () => {
    setIsVisible(false)
  }

  return <>
  <NavbarMenu onClick={onClick} selectedKeys={[]} mode="horizontal" items={isMobile ? mobileItems : desktopItems} />
  <DrawerMenu
        open={isVisible}
        onClose={closeDrawer}
        placement="left"
        title={<LogoImg src={logo} alt="Talez Consulting - Expert Yparéo" loading="lazy" />}
      >
        <SideMenu closeDrawer={closeDrawer} />
      </DrawerMenu>
  </>
}
export default Navbar
