const { default: api } = require('./api')

const endpoint = '/appointments'

const createAppointment = async (appointment) => {
  const config = {
    ...api.config,
    headers: {
      ...api.config.headers,
      'X-Graph-Token': `${window.localStorage.getItem('graph_token') || ''}`
    }
  }

  try {
    const res = await api.client.post(`${endpoint}/`, appointment, config)
    return res
  } catch (error) {
    return error
    // if (error.response.status===401)
    // if ( api.refreshAcessToken())
    //     res = await api.client.post(`${endpoint}/`,appointment)
    // return res
  }
}

const getAllAppointments = async () => {
  try {
    return await api.client.get(`${endpoint}/`)
  } catch (error) {
    return error
  }
}

export { createAppointment, getAllAppointments }
