import React from 'react'
import PieChart from './PieChart'
import AttributItem from './AttributItem'
import styled from 'styled-components'
import { Card, Spin } from 'antd'

const Wrapper = styled(Card)`
    .ant-card-body {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        padding: 0;
    }
    margin-bottom: 10px;
`
const Details = styled.div`
  width: 50%;
`

// eslint-disable-next-line react/prop-types
export default function Metrics ({ data = {} }) {
  const { all, accepted, rejected, pending } = data

  if (!all) return <Spin></Spin>

  return (
    <Wrapper>
        <Details>
            <AttributItem title="Nombre de candidatures" value={all.total} />
            <AttributItem title="Nombre de candidatures en cours" value={pending.total} />
            <AttributItem title="Nombre de candidatures refusées" value={rejected.total} />
            <AttributItem title="Nombre de candidatures acceptées" value={accepted.total} />
        </Details>
        <PieChart data={data} />
    </Wrapper>
  )
}
