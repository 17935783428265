/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react'
import { Skeleton, Spin, Table, Tabs, Tag, Button, Input, Space } from 'antd'
import EnrollmentDetails from '../components/EnrollmentDetails'
import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from '../shared/axiosInstance'
import dayjs from 'dayjs'
import { useAppContext } from '../contexts/AppContext'
import { calculateAge, getGenderLabel } from '../shared/helpers'
import Metrics from '../components/Metrics'
import styled from 'styled-components'
import { ExclamationCircleTwoTone, CloseCircleTwoTone, CheckCircleTwoTone, SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'

const Candidat = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 11px;
`
const Name = styled.div`
  color: grey;
`
const Training = styled.div`
  color: #16a085;
`
const Site = styled.div`
color: grey;
`

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    padding: 5px 20px;
    margin: 0!important;
    color: white;
    font-weight: bold;
  }
  [data-node-key="PENDING"] {
    background-color: #0088fe;
    border-radius: 8px 0 0 0;
  }
  [data-node-key="ACCEPTED"] {
    background-color: #16a085;
  }
  [data-node-key="REJECTED"] {
    background-color: #f71e1e;
    border-radius: 0 8px 0 0;
  }
`

const fetchData = async () => {
  const res = await axiosInstance.get('/enrollments')
  return res.data
}

const StatsTag = ({ accepted, rejected }) => {
  if (!accepted && !rejected) return <Tag color='blue'>En attente</Tag>
  else if (accepted) return <Tag color='green'>Accepté</Tag>
  else if (rejected) return <Tag color='red'>Refusé</Tag>
}

const EnrollmentList = () => {
  const [detailsOpen, setOpen] = useState(false)

  const { sites, trainings, selectEnrollment } = useAppContext()

  const { data: enrollments = [] } = useQuery({
    queryKey: ['enrollments'],
    queryFn: fetchData
  })

  const selectEnrollmentHandler = (enrollment) => {
    selectEnrollment(enrollment)
    setOpen(true)
  }

  const getDocIcon = (value) => value
    ? <CheckCircleTwoTone twoToneColor="#16a085"/>
    : <CloseCircleTwoTone twoToneColor="#f71010"/>

  // FILTRES
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{ padding: 8 }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Recherche ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Chercher
          </Button>
          <Button
            onClick={() => {
              if (clearFilters) handleReset(clearFilters)
              handleSearch(selectedKeys, confirm, dataIndex)
              close()
            }}
            size="small"
            style={{ width: 90 }}
          >
            Réinitialiser
          </Button>
        </Space>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#16a085' : undefined }}
      />
    ),

    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },

    render: (text) =>
      searchedColumn === dataIndex
        ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
          )
        : text
  })

  const getDocsFilter = (docType) => {
    return {
      filters: [
        { text: 'Présent', value: true },
        { text: 'Absent', value: false }
      ],
      onFilter: (value, record) => (record[docType] && value) || (!record[docType] && !value)
    }
  }

  const columns = [
    {
      title: "Date d'inscription",
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      ...getColumnSearchProps('createdAt')
    },
    {
      title: 'Candidat',
      dataIndex: 'candidat',
      key: 'candidat',
      align: 'center',
      ...getColumnSearchProps('candidat'),
      render: (candidat) => {
        const splitCandidat = candidat.split('|')
        return <Candidat>
          <Name>{splitCandidat[0]}</Name>
          <Training>{splitCandidat[1]}</Training>
          <Site>{splitCandidat[2]}</Site>
        </Candidat>
      }
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      align: 'center',
      ...getColumnSearchProps('age')
    },
    {
      title: 'Téléphone',
      dataIndex: 'telephone',
      key: 'telephone',
      align: 'center'
      // ...getColumnSearchProps('telephone')
    },
    {
      title: 'Mail',
      dataIndex: 'email',
      key: 'email',
      align: 'center'
      // ...getColumnSearchProps('email')
    },
    {
      title: 'Document',
      children: [
        {
          title: 'CV',
          dataIndex: 'cv',
          key: 'cv',
          align: 'center',
          render: getDocIcon,
          ...getDocsFilter('cv')
        },
        {
          title: 'LM',
          dataIndex: 'motivationLetter',
          key: 'motivationLetter',
          align: 'center',
          render: getDocIcon,
          ...getDocsFilter('motivationLetter')
        },
        {
          title: 'Bulletin',
          dataIndex: 'bulletin',
          key: 'bulletin',
          align: 'center',
          render: getDocIcon,
          ...getDocsFilter('bulletin')
        },
        {
          title: 'Autre',
          dataIndex: 'otherDoc',
          key: 'otherDoc',
          align: 'center',
          render: getDocIcon,
          ...getDocsFilter('otherDoc')
        }
      ]
    },
    {
      title: 'Etat',
      key: 'accepted',
      dataIndex: 'accepted',
      align: 'center',
      render: (_, { accepted, rejected }) => StatsTag({ accepted, rejected })
    }
  ]

  const isLoadingData = sites.length === 0 || trainings.length === 0

  const enrollmentsData = enrollments.data || []

  const statuses = [
    {
      key: 'PENDING',
      status: 'En cours',
      Icon: ExclamationCircleTwoTone,
      data: enrollmentsData.filter(r => !r.accepted && !r.rejected)
    },
    {
      key: 'ACCEPTED',
      status: 'Accepté',
      Icon: CheckCircleTwoTone,
      data: enrollmentsData.filter(r => r.accepted)
    },
    {
      key: 'REJECTED',
      status: 'Refusé',
      Icon: CloseCircleTwoTone,
      data: enrollmentsData.filter(r => r.rejected)
    }
  ]

  const items = statuses.map(({ key, status, Icon, data }) => {
    const candidates = data.map(candidate => {
      const { gender, firstName, surname, dateOfBirth, idSite, idTraining, createdAt } = candidate

      const st = sites.find(site => site.value === idSite) || { label: idSite }
      const tr = trainings.find(training => training.value === idTraining) || { label: idTraining }

      // if (sites.length === 0 || trainings.length === 0) return <Skeleton.Input active size={20} block />

      return {
        ...candidate,
        createdAt: dayjs(createdAt).format('DD/MM/YYYY'),
        age: calculateAge(dateOfBirth),
        candidat: ` ${getGenderLabel(gender)} ${firstName} ${surname}| ${tr.label} | ${st.label}`
      }
    })

    return {
      key,
      label: `${status} (${data.length})`,
      icon: <Icon />,
      children: <Table
        size='small'
        style={{ minWidth: '1100px' }}
        columns={columns}
        dataSource={candidates}
        pagination={{ pageSize: 6, hideOnSinglePage: true }}
        onRow={(record) => {
          return { onClick: () => selectEnrollmentHandler(record) }
        }}
      />
    }
  })

  return <div>
        <Spin tip="Chargement" size="large" spinning={isLoadingData}>
            <Metrics data={enrollments.metrics} />
            <StyledTabs defaultActiveKey="1" items={items} />
        </Spin>
        <EnrollmentDetails open={detailsOpen} onClose={() => setOpen(false)} />
    </div>
}

export default EnrollmentList
