/* eslint-disable camelcase */
import api from './api'

const endpoint = '/registrations'

const createRegistration = async (registration) => {
  try {
    return await api.client.post(`${endpoint}/`, registration)
  } catch (error) {
    return error
  }
}

const sendEmail = async (registration) => {
  try {
    return await api.client.post(`${endpoint}/send_email`, registration)
  } catch (error) {
    return error
  }
}

const getRegistrationByAppointment = async (appointment_id) => {
  try {
    return await api.client.get(`${endpoint}/${appointment_id}`)
  } catch (error) {
    return error
  }
}

const updateRegistration = async (registration) => {
  try {
    return await api.client.put(`${endpoint}/`, registration)
  } catch (error) {
    return error
  }
}

export { createRegistration, sendEmail, getRegistrationByAppointment, updateRegistration }
