import { Alert } from 'antd'
import React from 'react'

export default function Settings () {
  return (
    <div>
       <Alert message="-- TODO --" banner />
    </div>
  )
}
