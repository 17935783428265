/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ConfigProvider } from 'antd'
import { AppContextProvider } from './contexts/AppContext'
import frFR from 'antd/es/locale/fr_FR'
import PappersSearch from './pages/PappersSearch'
import Layout from './components/Layout'
import AddAppointment from './bundles/booking/containers/AddAppointment'
import Appointments from './bundles/booking/containers/Appointments'
import EnrollmentHome from './pages/EnrollmentHome'
import Enrollment from './pages/Enrollment'
import EnrollmentList from './pages/EnrollmentList'
import Settings from './pages/Settings'

import { is_signed_in } from './bundles/booking/api/calendar'
import { GlobalContext } from './bundles/booking/contexts/GlobalContext'
import AuthGraph from './pages/AuthGraph'

const queryClient = new QueryClient()

const theme = {
  components: {
    Table: {
      cellPaddingBlockSM: 3,
      cellPaddingInlineSM: 15
    },
    Tabs: {
      inkBarColor: 'purple',
      itemSelectedColor: '#fff',
      itemHoverColor: '#000'
    }
  },
  token: {
    colorPrimary: '#16a085'
  }
}

function App () {
  const [isSignedIn, setSignedIn] = useState(false)
  const [refresh, setRefresh] = useState('')

  const context = { isSignedIn, setSignedIn, refresh, setRefresh }

  useEffect(() => {
    const graphToken = localStorage.getItem('graph_token')
    if (graphToken) return setSignedIn(true)
    is_signed_in().then(res => setSignedIn(res.data?.success))
  }, [])

  return (
    <ConfigProvider theme={theme} locale={frFR}>
        <BrowserRouter>
          <Layout>
          <GlobalContext.Provider value={context}>
            <QueryClientProvider client={queryClient}>
              <AppContextProvider>
                <Routes>
                  <Route path="/" element={<PappersSearch />} />
                  <Route path="/inscription-accueil" element={<EnrollmentHome />} />
                  <Route path="/inscription" element={<Enrollment />} />
                  <Route path="/candidatures" element={<EnrollmentList />} />
                  <Route path="/ajouter-rdv" element={<AddAppointment />} />
                  <Route path="/inscriptions-rdv" element={<Appointments />} />
                  <Route path="/parametres" element={<Settings />} />
                  <Route path="/auth/graph/callback" element={<AuthGraph />} />
                </Routes>
              </AppContextProvider>
            </QueryClientProvider>
            </GlobalContext.Provider>
          </Layout>
        </BrowserRouter>
  </ConfigProvider>
  )
}

export default App
