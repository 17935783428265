import { string } from 'prop-types'
import React from 'react'

export default function AttributItem ({ title, value, link }) {
  const hasValue = value !== undefined && value !== null && value !== ''

  if (!hasValue) return null

  return (
    <div className='attribut'>
        {title && <span className='title'>{`${title} : `}</span>}
        {!link && <span className='value'>{value}</span>}
        {link && <a href={link + '/' + value} target='__blank' className='value'>{value}</a>}
    </div>
  )
}

AttributItem.propTypes = {
  title: string,
  value: string,
  link: string
}
